import { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import Edit from '@mui/icons-material/Edit';

import moment from "moment";

import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";

import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import { KENGEN } from "../../const/index";
import { EXPIRED_MOSHIKOMI_KIGEN_NISSU } from "../../const/message";
import LoginUserSelect from '../Common/LoginUserSelect';
import KokyakuSelect from '../Common/KokyakuSelect';
import BaitaiSelect from '../Common/BaitaiSelect';
import BaitaiShosaiSelect from '../Common/BaitaiShosaiSelect';
import ContentByosu from '../Common/ContentByosu';
import SimpleDatePicker from '../Common/DatePicker/SimpleDatePicker';
import HoueiHourSelect from '../Common/HoueiHourSelect';
import { postHoeimoshikomi, getHoeimoshikomiDetail } from "../../api/hoeimoshikomi";
import { HoeimoshikomiEditData, BaitaiDataSet, BaitaiShosaiDataSet, KokyakuDataSet, UserSettingDataSet } from "../../types/WebData";
import { HTTP_STATUS_CODE } from "../../const/index";
import LoadingButton from '@mui/lab/LoadingButton';
import HoeimoshikomiReadOnlyDialog from "./HoeimoshikomiReadOnlyDialog";

interface Props {
  hoeimoshikomiId: number;
  isOpen: boolean;
  isDisabled?: boolean;
}
const BANGUMIHYO_BYOSU_MAX = 3600;

/**
 * 放映申込ダイアログ
 *
 * @param {Props} { hoeimoshikomiId }
 * @return {*} 
 */
const HoeimoshikomiEditDialog = ({ hoeimoshikomiId, isOpen, isDisabled = false }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [authInfo] = useContext(AuthInfoContext);
  const [open, setOpen] = useState(isOpen);
  const { handleSubmit } = useForm<any>();

  const [id, setId] = useState<number>(0);
  const [loginUser, setLoginUser] = useState<UserSettingDataSet | null>(null);
  const [kokyaku, setKokyaku] = useState<KokyakuDataSet | null>(null);
  const [baitai, setBaitai] = useState<BaitaiDataSet | null>(null);
  const [baitaiShosai, setBaitaiShosai] = useState<BaitaiShosaiDataSet | null>(null);
  const [bangumiMei, setBangumiMei] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string | null>("");
  const [endTime, setEndTime] = useState<string | null>("");
  const [contentByosu, setContentByosu] = useState<number>(0);
  const [saiseiKaisu, setSaiseiKaisu] = useState<string>("");
  const [jikantaiShiteiFlg, setJikantaiShiteiFlg] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  // 詳細データ保持用　変更したのかどうかの判定に使います
  const [henkomaeData, setDataBeforeUpdate] = useState<HoeimoshikomiEditData | null>(null);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  // エラーメッセージ表示用
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessageBangumiMei, setErrorMessageBangumiMei] = useState<string>("");
  const [errorMessageKokyaku, setErrorMessageKokyaku] = useState<string>("");
  const [errorMessageBaitai, setErrorMessageBaitai] = useState<string>("");
  const [errorMessageBaitaiShosai, setErrorMessageBaitaiShosai] = useState<string>("");
  const [errorMessageContentByosu, setErrorMessageContentByosu] = useState<string>("");
  const [errorMessageSaiseiKaisu, setErrorMessageSaiseiKaisu] = useState<string>("");
  const [errorMessageStartdate, setErrorMessageStartdate] = useState<string>("");
  const [errorMessageEnddate, setErrorMessageEnddate] = useState<string>("");
  const [errorMessageStartTime, setErrorMessageStartTime] = useState<string>("");
  const [errorMessageEndTime, setErrorMessageEndTime] = useState<string>("");

  /**
   * エラーメッセージ初期化処理
   *
   */
  const initErrorMessage = () => {
    setErrorMessage("");
    setErrorMessageBangumiMei("");
    setErrorMessageKokyaku("");
    setErrorMessageBaitai("");
    setErrorMessageBaitaiShosai("");
    setErrorMessageContentByosu("");
    setErrorMessageSaiseiKaisu("");
    setErrorMessageStartdate("");
    setErrorMessageEnddate("");
    setErrorMessageStartTime("");
    setErrorMessageEndTime("");
  }

  // 媒体詳細情報を初期化
  useEffect(() => {
    if (baitai?.id !== baitaiShosai?.baitai_id) {
      setBaitaiShosai({ id: 0 });
      setStartTime("");
      setEndTime("");
    }
  }, [baitai])

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    // エラーメッセージ初期化
    initErrorMessage();
    setId(hoeimoshikomiId);
    setIsCreate(hoeimoshikomiId === 0);

    if (hoeimoshikomiId === 0) {
      setLoginUser({ id: authInfo.id, user_id: "", password: "", kaisha_id: authInfo.kaisha_id, kaisha_mei: "", shimei: "", mail_address: "", haishin_tanto_flg: false, update_dt: "" })
      setKokyaku(null)
      setBaitai(null)
      setBaitaiShosai(null)
      setBangumiMei("")
      setStartDate(null);
      setEndDate(null);
      setJikantaiShiteiFlg(false);
      setStartTime(null);
      setEndTime(null);
      setContentByosu(15)
      setSaiseiKaisu("1")
    } else {
      // 放映申込詳細取得
      const res = await getHoeimoshikomiDetail(hoeimoshikomiId);
      if (res?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }

      const result = res.data[0];
      setLoginUser({ id: result.loginuser_id, user_id: "", password: "", kaisha_id: result.kaisha_id, kaisha_mei: "", shimei: "", mail_address: "", haishin_tanto_flg: false, update_dt: "" })
      setKokyaku({ id: result.kokyaku_id, jusho: result.jusho, kokyaku_mei: result.kokyaku_mei, loginuser_id: 0, kaisha_id: 0, update_dt: "" })
      setBaitai({ id: result.baitai_id, baitai_mei: result.baitai_mei, hoei_kaishi_jikan: result.hoei_kaishi_jikan, hoei_shuryo_jikan: result.hoei_shuryo_jikan })
      setBaitaiShosai({ id: result.baitai_shosai_id, baitai_id: result.baitai_id, moshikomi_kigen_nissu: result.moshikomi_kigen_nissu })
      setBangumiMei(result.bangumi_mei)
      setStartDate(new Date(moment(result.haishin_kaishibi).format('YYYY/MM/DD')));
      setEndDate(result.haishin_shuryobi ? new Date(moment(result.haishin_shuryobi).format('YYYY/MM/DD')) : null);
      setJikantaiShiteiFlg(result.jikantai_shitei_flg);
      setStartTime(result.haishin_kaishijikan);
      setEndTime(result.haishin_shuryojikan);
      if (result.haishin_kaishijikan) setStartTime(result.haishin_kaishijikan);
      if (result.haishin_shuryojikan) setEndTime(result.haishin_shuryojikan);
      setContentByosu(result.content_byosu)
      setSaiseiKaisu("" + result.saisei_kaisu)

      setDataBeforeUpdate({
        id: hoeimoshikomiId,
        loginuser_id: loginUser?.id,
        kaisha_id: loginUser?.kaisha_id,
        kokyaku_id: result.kokyaku_id,
        baitai_id: result.baitai_id,
        baitai_shosai_id: result.baitai_shosai_id,
        bangumi_mei: result.bangumi_mei,
        haishin_kaishibi_henkomae: moment(result.haishin_kaishibi).format("YYYY-MM-DD HH:mm:ssZ"),
        haishin_shuryobi_henkomae: result.haishin_shuryobi ? moment(result.haishin_shuryobi).format("YYYY-MM-DD HH:mm:ssZ") : "",
        haishin_kaishibi: ``,
        haishin_shuryobi: ``,
        jikantai_shitei_flg: result.jikantai_shitei_flg,
        haishin_kaishijikan: result.haishin_kaishijikan ? moment(result.haishin_kaishijikan, 'HH:mm').format('HH:mm') : "",
        haishin_shuryojikan: result.haishin_shuryojikan ? moment(result.haishin_shuryojikan, 'HH:mm').format('HH:mm') : "",
        content_byosu: result.content_byosu,
        saisei_kaisu: 0,
        update_dt: moment(result.update_dt).format("YYYY-MM-DD HH:mm:ss")
      })

      if (authInfo.kengen !== KENGEN.ADMIN) {
        // 媒体詳細毎の申込期限日数を過ぎていないかチェック
        const dateFrom = moment(result.haishin_kaishibi).add(1, 'days');
        const dateTo = moment();
        const diffDays = dateFrom.diff(dateTo, 'days');
        if (diffDays < result.moshikomi_kigen_nissu && authInfo.kengen === KENGEN.DAIRITEN) {
          setIsReadOnly(true);
          await reloadContext?.setSnackbarInfo({
            isOpen: true,
            type: "warning",
            message: EXPIRED_MOSHIKOMI_KIGEN_NISSU,
          });
        }
        if ((result.omakase_pack_flg && authInfo.kaisha_id !== result.kaisha_id) || (authInfo.kaisha_id !== result.kaisha_id)) {
          setIsReadOnly(true);
        }
      }
    }


  }

  const handleClickOpen = async () => {
    fetchData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // コピーして新規ボタン押下時の処理
  const handleCopy = () => {
    setId(0);
    setIsCreate(true);
    if (authInfo.kaisha_id != loginUser?.kaisha_id) {
      setKokyaku(null);
    }
    setLoginUser({ id: authInfo.id, user_id: "", password: "", kaisha_id: authInfo.kaisha_id, kaisha_mei: "", shimei: "", mail_address: "", haishin_tanto_flg: false, update_dt: "" })
  };

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    // エラーメッセージ初期化
    initErrorMessage();
    // バリデーションチェック
    if (!baitai || baitai?.id === 0) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageBaitai("媒体名を選択してください。");
    }
    if (!baitaiShosai || baitaiShosai?.id === 0) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageBaitaiShosai("画面名を選択してください。");
    }
    if (bangumiMei === "") {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageBangumiMei("番組名を入力してください。");
    }
    if (!kokyaku || kokyaku.id === 0) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageKokyaku("広告主名を選択してください。");
    }
    if (!startDate) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageStartdate("放映開始日を入力してください。");
    }
    if (!moment(startDate).isValid()) {
      return setErrorMessageStartdate("不正な入力形式です。");
    }
    if (endDate && !moment(endDate).isValid()) {
      return setErrorMessageEnddate("不正な入力形式です。");
    }
    if (jikantaiShiteiFlg && !startTime) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageStartTime("放映開始時間を入力してください。");
    }
    if (jikantaiShiteiFlg && !endTime) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageEndTime("放映終了時間を入力してください。");
    }
    if (contentByosu === 0) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageContentByosu("コンテンツ秒数は15秒以上にしてください。");
    }
    if (contentByosu * +saiseiKaisu > BANGUMIHYO_BYOSU_MAX) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageSaiseiKaisu("コンテンツ秒数 * 再生回数が3600秒を超えています。");
    }
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    if (startDateMoment.isAfter(endDateMoment, 'day')) {
      setErrorMessage("入力項目に誤りがあります。");
      return setErrorMessageEnddate("放映終了日は放映開始日より未来の日付にしてください。");
    }
    if (authInfo.kengen === KENGEN.DAIRITEN) {
      // 媒体詳細毎の申込期限日数を過ぎていないかチェック
      const dateFrom = moment(startDate).add(1, 'days');
      const dateTo = moment();
      const diffDays = dateFrom.diff(dateTo, 'days');
      if (baitaiShosai?.moshikomi_kigen_nissu && diffDays < baitaiShosai?.moshikomi_kigen_nissu) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageStartdate("申込期限日数を過ぎているため新規登録、変更はできません。");
      }
    }

    // 時間帯指定を行った場合のバリデーションチェック
    if (jikantaiShiteiFlg) {
      // 媒体の開始終了時間を取得し、終了時間は調整を行う      
      const baitaiHoeiKaishiJikan = +moment(baitai?.hoei_kaishi_jikan, 'HH:mm').format('HHmm');
      const baitaiHoeiShuryoJikan = +moment(baitai?.hoei_shuryo_jikan, 'HH:mm').format('HHmm');
      const baitaiHoeiShuryoJikanOver24 = (baitaiHoeiShuryoJikan <= baitaiHoeiKaishiJikan) ? baitaiHoeiShuryoJikan + 2400 : baitaiHoeiShuryoJikan;  //放映終了時間が0時過ぎの場合
      const isBaitai24JikanHoei = baitaiHoeiKaishiJikan === baitaiHoeiShuryoJikan;  //24時間放映か否か

      const startTimeNum = +moment(startTime, 'HH:mm').format('HHmm');
      const endTimeNum = +moment(endTime, 'HH:mm').format('HHmm');
      const endTimeOver24 = (endTimeNum < startTimeNum) ? endTimeNum + 2400 : endTimeNum;  //放映終了時間が0時過ぎの場合

      // 放映時間が1時間以上の間隔が空いていないためNG
      if (!isBaitai24JikanHoei && startTimeNum >= endTimeOver24) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageEndTime("放映終了時間は放映開始時間の1時間以上後の時刻を指定してください。");
      }
      // 時間帯指定が放映時間から外れているためNG
      if (!isBaitai24JikanHoei && (baitaiHoeiKaishiJikan > endTimeOver24 || baitaiHoeiShuryoJikanOver24 < endTimeOver24)) {
        setErrorMessage("入力項目に誤りがあります。");
        return setErrorMessageEndTime("放映終了時間は媒体の放映時間内で指定してください。");
      }
    }

    const { postData, isChanged }: { postData: HoeimoshikomiEditData, isChanged: boolean } = createPostData();
    setLoad(true);
    if (isChanged) {
      // 変更箇所がある場合、API呼び出し
      const result = await postHoeimoshikomi(postData);

      if (result.overMaxDays && result.overMaxDays.length > 0) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "warning",
          message: `以下の日付にて放映申込上限に達しているため申込ができませんでした。\r\n${result.overMaxDays.map(d => moment(d).format("YYYY/MM/DD")).join("\r\n")}`,
        });
      } else {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: result.isError ? "error" : "success",
          message: result.isError ? result.status === HTTP_STATUS_CODE.CONFLICT_ERROR ? result.message : "更新に失敗しました。" : "保存しました。",
        });
      }
    } else {
      // 変更箇所が無い場合、API呼び出しスキップ
      await reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "success",
        message: "保存しました。",
      });
    }
    await reloadSetting();
    setLoad(false);
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  /**
   * 新規登録、更新時のPostData作成
   * @return {*} 
   */
  const createPostData = () => {
    let isChanged = false;
    if (isCreate) {
      const postData = {
        id: id,
        kaisha_id: loginUser?.kaisha_id,
        loginuser_id: loginUser?.id,
        kokyaku_id: kokyaku?.id,
        baitai_shosai_id: baitaiShosai?.id,
        bangumi_mei: bangumiMei,
        haishin_kaishibi_henkomae: "",
        haishin_shuryobi_henkomae: "",
        haishin_kaishibi: moment(startDate).format("YYYY-MM-DD HH:mm:ssZ"),
        haishin_shuryobi: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ssZ") : "",
        jikantai_shitei_flg: jikantaiShiteiFlg,
        haishin_kaishijikan: startTime ? startTime : "",
        haishin_shuryojikan: endTime ? endTime : "",
        content_byosu: contentByosu,
        saisei_kaisu: +saiseiKaisu
      } as HoeimoshikomiEditData
      isChanged = true;
      return { postData, isChanged };
    } else {
      // APIで必ず使うものは初期化時に格納する
      const postData = {
        id: hoeimoshikomiId,
        kaisha_id: loginUser?.kaisha_id,
        loginuser_id: loginUser?.id,
        kokyaku_id: 0,
        baitai_shosai_id: baitaiShosai?.id,
        bangumi_mei: bangumiMei,
        haishin_shuryobi_henkomae: henkomaeData?.haishin_shuryobi_henkomae ? henkomaeData?.haishin_shuryobi_henkomae : null,
        haishin_kaishibi: "",
        haishin_shuryobi: "",
        jikantai_shitei_flg: undefined,
        haishin_kaishijikan: "",
        haishin_shuryojikan: "",
        content_byosu: 0,
        saisei_kaisu: 0,
        update_dt: henkomaeData?.update_dt
      } as HoeimoshikomiEditData;

      if (loginUser?.id !== henkomaeData?.loginuser_id) {
        // 申込者が変更されたら更新する、postData初期化時に格納済み
        isChanged = true;
      }
      // 変更されたもののみPostDataに詰める
      if (kokyaku != null && kokyaku?.id !== henkomaeData?.kokyaku_id) {
        isChanged = true;
        postData.kokyaku_id = kokyaku.id
      }
      if (baitaiShosai != null && baitaiShosai?.id !== henkomaeData?.baitai_shosai_id) {
        // 媒体詳細IDは番組表の更新に必要なため、postData初期化時に格納済み
        isChanged = true;
      }
      if (bangumiMei !== henkomaeData?.bangumi_mei) {
        // 番組名はお知らせ作成に必要なため、postData初期化時に格納済み
        isChanged = true;
      }
      if (contentByosu !== henkomaeData?.content_byosu) {
        isChanged = true;
        postData.content_byosu = contentByosu
      }
      // 放映開始、終了時間
      if (jikantaiShiteiFlg !== henkomaeData?.jikantai_shitei_flg) {
        isChanged = true;
        postData.jikantai_shitei_flg = jikantaiShiteiFlg;
        if (!jikantaiShiteiFlg) {
          postData.haishin_kaishijikan = null;
          postData.haishin_shuryojikan = null;
        } else {
          postData.haishin_kaishijikan = startTime
          postData.haishin_shuryojikan = endTime
        }
      } else if (jikantaiShiteiFlg) {
        if (startTime !== henkomaeData?.haishin_kaishijikan) {
          isChanged = true;
          postData.haishin_kaishijikan = startTime
        }
        if (endTime !== henkomaeData?.haishin_shuryojikan) {
          isChanged = true;
          postData.haishin_shuryojikan = endTime
        }
      }

      // 放映日が変更された場合
      const startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ssZ");
      const endDateFormat = endDate === null ? null : endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ssZ") : "";
      if (
        startDateFormat !== henkomaeData?.haishin_kaishibi_henkomae
        ||
        endDateFormat !== henkomaeData?.haishin_shuryobi_henkomae
      ) {
        isChanged = true;
        postData.haishin_kaishibi = startDateFormat;
        postData.haishin_shuryobi = endDateFormat ? endDateFormat : null;
      }

      return { postData, isChanged };
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      {
        hoeimoshikomiId === 0 ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            {authInfo.kengen === KENGEN.KANRI ? "新規コンテンツ登録" : "新規申込"}
          </Button>
          :
          !isOpen && (
            <IconButton color="primary" onClick={handleClickOpen} disabled={isDisabled}>
              <Edit fontSize="large" />
            </IconButton>
          )
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        {isReadOnly ?
          <>
            <HoeimoshikomiReadOnlyDialog hoeimoshikomiId={hoeimoshikomiId} />
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            </DialogActions>
          </>
          :
          <form onSubmit={handleSubmit(handleUpdate)}>
            <Typography component="h3" variant="h6" mt={1} ml={2}>
              {isCreate ?
                (authInfo.kengen === KENGEN.KANRI ? "新規コンテンツ登録" : "新規申込登録")
                :
                (authInfo.kengen === KENGEN.KANRI ? "コンテンツ編集" : "放映申込編集")
              }
            </Typography>
            <DialogContent sx={{ paddingTop: 0 }}>
              {authInfo.kengen === KENGEN.ADMIN ? <LoginUserSelect loginUser={loginUser} setLoginUser={setLoginUser} label="申込者 *" disabled={isReadOnly} /> : ""}
              <BaitaiSelect
                baitai={baitai}
                setBaitai={setBaitai}
                label="媒体名 *"
                is_visible={false}
              />
              {errorMessageBaitai && (
                <small className={"error-message"}>{errorMessageBaitai}</small>
              )}
              <BaitaiShosaiSelect
                baitaiId={baitai?.id}
                baitaiShosai={baitaiShosai}
                setBaitaiShosai={setBaitaiShosai}
                label="画面名 *"
              />
              {errorMessageBaitaiShosai && (
                <small className={"error-message"}>{errorMessageBaitaiShosai}</small>
              )}
              <TextField
                id="programName"
                label="番組名 *"
                value={bangumiMei}
                onChange={e => setBangumiMei(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ marginTop: 1 }}
              />
              {errorMessageBangumiMei && (
                <small className={"error-message"}>{errorMessageBangumiMei}</small>
              )}
              <KokyakuSelect kokyaku={kokyaku} setKokyaku={setKokyaku} loginUser={loginUser} label="広告主名 *" disabled={isReadOnly} />
              {errorMessageKokyaku && (
                <small className={"error-message"}>{errorMessageKokyaku}</small>
              )}
              <TextField
                id="address"
                label="住所"
                value={kokyaku?.jusho || ''}
                fullWidth
                variant="outlined"
                disabled
                sx={{ marginTop: 1 }}
              />
              <SimpleDatePicker
                date={startDate}
                setDate={setStartDate}
                label="放映開始日 *"
                minDate={(isCreate) ? new Date() : undefined}
              />
              {errorMessageStartdate && (
                <small className={"error-message"}>{errorMessageStartdate}</small>
              )}
              <SimpleDatePicker
                date={endDate}
                setDate={setEndDate}
                label="放映終了日"
                minDate={(isCreate) ? new Date() : undefined}
              />
              {errorMessageEnddate && (
                <small className={"error-message"}>{errorMessageEnddate}</small>
              )}
              <FormControlLabel
                control={
                  <Checkbox checked={jikantaiShiteiFlg} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setJikantaiShiteiFlg(event.target.checked);
                  }}
                  />
                }
                label="時間帯指定放映を行う"
                sx={{ display: "block" }}
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <HoueiHourSelect
                    time={startTime}
                    setTime={setStartTime}
                    baitaiId={baitai?.id}
                    label="放映開始時間"
                    disabled={!jikantaiShiteiFlg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <HoueiHourSelect
                    time={endTime}
                    setTime={setEndTime}
                    baitaiId={baitai?.id}
                    label="放映終了時間"
                    disabled={!jikantaiShiteiFlg}
                  />
                </Grid>
              </Grid>
              {errorMessageStartTime && (
                <small className={"error-message"}>{errorMessageStartTime}</small>
              )}
              {errorMessageEndTime && (
                <small className={"error-message"}>{errorMessageEndTime}</small>
              )}
              <Typography component="h3" variant="subtitle1">
                1動画の再生時間
              </Typography>
              <ContentByosu contentByosu={contentByosu} setContentByosu={setContentByosu} disabled={!isCreate} />
              {errorMessageContentByosu && (
                <small className={"error-message"}>{errorMessageContentByosu}</small>
              )}
              <TextField
                id="saiseiKaisu"
                value={saiseiKaisu}
                onChange={e => { return (+e.target.value === 0) ? setSaiseiKaisu("") : isNaN(+e.target.value) ? "" : setSaiseiKaisu(e.target.value) }}
                label="1動画の再生回数"
                fullWidth
                variant="outlined"
                disabled={!isCreate}
                inputProps={{ maxLength: 10 }}
                sx={{ marginTop: 1 }}
              />
              {errorMessageSaiseiKaisu && (
                <small className={"error-message"}>{errorMessageSaiseiKaisu}</small>
              )}
            </DialogContent>

            <DialogActions>
              {errorMessage && (
                <small className={"error-message"} style={{ marginRight: 8 }}>{errorMessage}</small>
              )}
              {isCreate || <Button variant="contained" color="info" onClick={handleCopy}>コピーして新規登録</Button>
              }
              <Button variant="outlined" onClick={handleClose}>閉じる</Button>
              <LoadingButton loading={load} variant="contained" type="submit">登録</LoadingButton>
            </DialogActions>
          </form>
        }
      </Dialog >
    </Box >
  );
}

export default HoeimoshikomiEditDialog;